/* eslint-disable prettier/prettier */
import type { SearchState } from '@faststore/sdk'
import { parseSearchState, SearchProvider } from '@faststore/sdk'
import type {
  ClusterPageQueryQuery,
  ClusterPageQueryQueryVariables,
  SiteSiteMetadata,
} from '@generated/graphql'
import type { PageProps } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { useMemo } from 'react'
import StockAvailable from 'src/components/common/StockAvailable/StockAvailable'
import { useCmsLpbombazosQuery } from 'src/components/hooks/useCmsLpBombazosQuery'
import { CarouselBanner } from 'src/components/sections/CarouselBanner'
import Newsletter from 'src/components/sections/Newsletter'
import ProductGallery from 'src/components/sections/ProductGallery'
import { ITEMS_PER_PAGE } from 'src/constants'
import { applySearchState } from 'src/sdk/search/state'
import { useSession } from 'src/sdk/session'

import 'src/styles/pages/plp.scss'

interface ServerDataResponse {
  cluster: { name: string; id: string }
}

type Props = PageProps<
  ClusterPageQueryQuery,
  ClusterPageQueryQueryVariables,
  unknown,
  ServerDataResponse
> & { slug: string; data: TAllCmsInstitutional }

interface TAllCmsInstitutional {
  allCmsInstitutionalPage: TEdges
  site: {
    siteMetadata: SiteSiteMetadata
  }
}

type TEdges = {
  edges: Array<{ node: { sections: Array<{ data: { iframe: string } }> } }>
}

function Page(props: Props) {
  const {
    serverData,
    location: { href, pathname },
  } = props

  const useSearchParams = (facets?: any): SearchState => {
    const selectedSearchFacets = facets

    return useMemo(() => {
      const maybeState = href ? parseSearchState(new URL(href)) : null

      return {
        page: maybeState?.page ?? 0,
        base: maybeState?.base ?? pathname,
        selectedFacets:
          maybeState && maybeState.selectedFacets.length > 0
            ? maybeState.selectedFacets
            : selectedSearchFacets ?? [],
        term: maybeState?.term ?? null,
        sort: maybeState?.sort ?? 'score_desc',
      }
    }, [selectedSearchFacets])
  }

  const { site, bannerImages, collectionTitle, collectionID } =
    useCmsLpbombazosQuery()

  const selectedFacets = [
    {
      key: 'productClusterIds',
      value: collectionID ? String(collectionID) : '',
    },
  ]

  const { locale } = useSession()
  const searchParams = useSearchParams(selectedFacets)

  const cluster = serverData?.cluster
  const title = site?.title ?? ''

  return (
    <SearchProvider
      onChange={applySearchState}
      itemsPerPage={ITEMS_PER_PAGE}
      {...searchParams}
    >
      {/* SEO */}
      <GatsbySeo
        title={title}
        titleTemplate={site?.titleTemplate ?? ''}
        description={site?.description ?? ''}
        // eslint-disable-next-line no-restricted-globals
        canonical=""
        language={locale}
        openGraph={{
          type: 'website',
          title,
          description: site?.description ?? '',
        }}
      />
      <CarouselBanner data-testid="Carousel" allItems={bannerImages} />
      <div data-fs-category-results>
        <ProductGallery
          title={collectionTitle}
          collectionName={cluster?.name}
          useFilters={false}
        />
      </div>
      <StockAvailable icon />
      <Newsletter
        title="Recibí promociones y novedades"
        subtitle="Ingresá tu correo electrónico"
      />
    </SearchProvider>
  )
}

export default Page
export { Head } from 'src/components/common/Head'
