import './carousel-banner.scss'
import Carousel from '../../ui/Carousel'
import DynamicImages from './DynamicImage'

interface ICarouselBanner {
  allItems: BannerImagesData[]
}

function CarouselBanner({ allItems }: ICarouselBanner) {
  const allImages = allItems ?? []

  return (
    <Carousel
      infiniteMode
      controls="complete"
      timeout={8000}
      transition={{
        duration: 600,
        property: 'transform',
      }}
      carouselId="banner-carousel"
      carouselName="Banner Carousel"
    >
      {allImages.map((image, idx) => (
        <>
          <div className="hidden-mobile-banner" key={`desktop-${idx}`}>
            {image?.sources[0]?.srcSet && (
              <DynamicImages
                valueLoading="eager"
                imageValues={{
                  href: image.sources[0].srcSet,
                  title: image.alt,
                  id: `desktop-home-banner-${idx}`,
                  link: image.href,
                }}
                imageWidth={{
                  desktop: 1903,
                  mobile: 375,
                }}
                imageHeight={{
                  desktop: 299,
                  mobile: 233,
                }}
              />
            )}
          </div>

          <div className="display-mobile-banner" key={`mobile-${idx}`}>
            {image?.sources[1]?.srcSet && (
              <DynamicImages
                valueLoading="eager"
                imageValues={{
                  href: image.sources[1].srcSet,
                  title: image.alt,
                  id: `mobile-home-banner-${idx}`,
                  link: image.href,
                }}
                imageWidth={{
                  desktop: 1903,
                  mobile: 375,
                }}
                imageHeight={{
                  desktop: 299,
                  mobile: 233,
                }}
              />
            )}
          </div>
        </>
      ))}
    </Carousel>
  )
}

export default CarouselBanner
